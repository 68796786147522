<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 380 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
    <attribute-value-form
      :resource="resource"
      :loading="loading"
      :errors="errors"
      @submit="handleSubmit"
    />
    <p
      v-if="!resource && attributeTypeId"
      class="text-center"
    >
      Attach to Type #{{ attributeTypeId }}
    </p>
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { storeAttributeValue, updateAttributeValue } from '@api/product'
import { mdiClose } from '@mdi/js'
import { ref } from '@vue/composition-api'
import AttributeValueForm from './AttributeValueForm.vue'

export default {
  components: { AttributeValueForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    attributeTypeId: {
      type: Number,
      default: null,
    },
    resource: {
      type: [Boolean, Object],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      const data = { ...form }
      if (!props.resource) {
        data.attribute_type_id = props.attributeTypeId
      }
      const request = props.resource ? updateAttributeValue(props.resource.id, data) : storeAttributeValue(data)
      await request
        .then(res => {
          emit(props.resource ? 'updated' : 'created', res.data.data)
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
