<template>
  <v-form ref="formElem">
    <v-card>
      <translation-locales />

      <v-card-text>
        <translatable-input
          v-model="form.value"
          label="Display Name"
          class="mt-5"
        />

        <v-text-field
          v-model="form.key"
          label="Unique Key"
          placeholder="e.g. blue, red, 64gb"
        />

        <div class="demo-space-x">
          <v-switch
            v-model="form.active"
            color="primary"
            :label="form.active ? 'Active' : 'Inactive'"
            :disabled="disabledFields.indexOf('active') >= 0"
          ></v-switch>
        </div>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput } from '@/components'
import { TranslationLocales } from '@/components'
import { useAlphaDash, useSlugify, useTranslatable } from '@/composables'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: { TranslatableInput, TranslationLocales },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const initialForm = {
      key: props.resource ? props.resource.key : '',
      value: useTranslatable(props.resource, 'value'),
      active: props.resource ? props.resource.active : true,
      attribute_type_id: props.resource ? props.resource.attribute_type_id : false,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      form.value.key = useAlphaDash(useSlugify(form.value.key))
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,

      validate,
      required,
    }
  },
}
</script>
