import { fetchAllAttributeValues, updateAttributeValue } from '@/api/product'
import { useTableOptions } from '@/composables'
import productStore from '@/modules/product/store'
import router from '@/router'
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useUsersList() {
  const attributeValueListTable = ref([])

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'KEY', value: 'key' },
    { text: 'VALUE', value: 'attributeValue', sortable: false },
    { text: 'STATUS', value: 'status', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const totalListTable = ref(0)
  const loading = ref(false)
  const totalLocal = ref([])
  const selectedRows = ref([])
  const attributeTypeFilter = ref(cached[2] || null)

  // fetch data
  const loadAttributeValues = () =>
    fetchAllAttributeValues(
      useTableOptions(options.value, {
        search: searchQuery.value,
        attributeTypeId: attributeTypeFilter.value,

        // root_only: 1,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data

        attributeValueListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const updateAllAttributeValues = async (updates = {}) => {
    loading.value = true
    for (const attributeValue of selectedRows.value) {
      const data = { ...updates }
      data.name = attributeValue.name
      await updateAttributeValue(attributeValue.id, data)
    }
    loadAttributeValues()
    loading.value = false
    selectedRows.value = []
  }

  const cachable = [options, searchQuery, attributeTypeFilter]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      selectedRows.value = []
      loadAttributeValues()
      cache()
    }, 300),
  )

  onMounted(() => {
    const attributeTypeId = router.currentRoute.query.attribute_type_id
    if (attributeTypeId) {
      attributeTypeFilter.value = attributeTypeId
    }
  })

  const attributeTypesOptions = computed(() => productStore.state.attributeTypes.map(type => ({ title: type.name.en, value: type.id })))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    attributeValueListTable,
    tableColumns,
    searchQuery,
    totalListTable,
    loading,
    options,
    attributeTypeFilter,
    totalLocal,
    selectedRows,
    attributeTypesOptions,

    loadAttributeValues,
    updateAllAttributeValues,
  }
}
